@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .button {
        @apply inline-flex items-center w-max rounded shadow-sm px-4 py-2 text-sm font-medium transition-colors font-medium;
    }

    .primary-button {
        @apply text-dark bg-primary;
    }

    .blue-button {
        @apply text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-blue-500 focus:ring-inset focus:ring-1 ;
    }

    .primary-button-outlined {
        @apply text-primary bg-white;
    }

    .gray-button {
        @apply focus:ring-inset text-blue-gray-600 focus:ring-1 bg-blue-gray-100 hover:bg-blue-gray-200 focus:outline-none focus:ring-blue-gray-300;
    }

    .green-button {
        @apply text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-green-500 focus:ring-inset focus:ring-1 ;
    }

    html {
        @apply text-blue-gray-700
    }
}

.recommendation {
    width: 400px;
}

.scrollbar {
    scrollbar-color: #cacaca transparent !important;
    scrollbar-width: thin !important;
}

.scrollbar::-webkit-scrollbar {
    width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #cacaca;
    border-radius: 5px;
}

.scrollbar ::-webkit-scrollbar {
    width: 6px;
}

.scrollbar ::-webkit-scrollbar-track {
    background-color: transparent;
}

.scrollbar ::-webkit-scrollbar-thumb {
    background-color: #cacaca;
    border-radius: 5px;
}
